import { portalAPI, portalURL } from '@/helpers/environmentHelper';
import { useAppSelector } from '@/state/hooks';
import { User } from '@/state/reducers/authSlice';
import { selectNotificationSettings } from '@/state/reducers/settingSlice';
import axios from 'axios';
import { useCallback } from 'react';
import { useSso } from '../providers/SSOProvider';
import { parseNotificationSettings, toastError } from './useToast';

// interface UseXplorPortalProps {
//     credentials: User;
// }

interface UseXplorPortalReturn {
    onXplorPortal: () => void;
}

const useXplorPortal = (credentials?: User): UseXplorPortalReturn => {
    const { jwt } = useSso();
    const notifications = useAppSelector(selectNotificationSettings);

    const onXplorPortal = useCallback(async () => {
        try {
            if (portalURL) {
                if (jwt) {
                    const response = await axios.post(portalAPI('/api/auth/exchange'), {
                        authToken: credentials?.authToken
                    });
                    const ssoToken = response.data.ssoToken;
                    window.open(portalAPI(`/login?ssoToken=${ssoToken}`), '_blank');
                } else {
                    window.open(portalAPI(`/login`), '_blank');
                }
            }
        } catch (e) {
            const toastConfig = parseNotificationSettings(notifications);
            toastError({
                body: (
                    <div>
                        <p>Please try again or contact support. </p>
                    </div>
                ),
                title: 'Failed to direct user to the Client Portal',
                persist: true,
                toastConfig
            });
        }
    }, [credentials?.authToken, jwt]);

    return { onXplorPortal };
};

export default useXplorPortal;
