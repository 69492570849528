import axios from 'axios';

export async function logToServer(level: 'info' | 'error' | 'warn' = 'info', message: string) {
    try {
        const expressUrl = window.config?.integration?.express?.rest;
        return await axios.post(`${expressUrl}/logs`, { layout: 'wl-test', level, message });
    } catch (error) {
        return await console.error(error);
    }
}

// headers: {
//     "Content-Type": "application/json",
// },
