import CurrencyIcon from '@/components/common/CurrencyIcon';
import { qtyFormatterConfig } from '@/config/config';
import { CelerBalance } from '@/state/reducers/balanceSlice';
import { useCurrency } from '@/utils/hooks/useCurrency';
import cn from 'classnames';
import { useMemo } from 'react';
import { useNumberFormatter } from 'react-aria';

interface OldLiteBalanceProps {
    balance: CelerBalance;
    onClick: (e: any, balance: CelerBalance) => void;
}

export const OldLiteBalance = ({ balance, onClick }: OldLiteBalanceProps) => {
    const formatQty = useNumberFormatter(qtyFormatterConfig);
    const { longName, show } = useCurrency(balance.currency);

    const balanceColor = useMemo(() => {
        if (balance.netPosition === 0) return 'text-neutral-200';
        else if (balance.netPosition > 0) return 'text-brand-primary';
        else return 'text-brand-red';
    }, [balance.netPosition]);

    return (
        <div
            className="flex w-full items-center gap-3 p-1 px-3 rounded-sm text-xs sm:text-sm hover:bg-neutral-700 focus:bg-neutral-700 cursor-pointer"
            onClick={(e) => onClick(e, balance)}>
            <div className="flex flex-[1_1_0] items-center gap-2">
                <CurrencyIcon ccy={show.toLowerCase()} />
                <div className="flex flex-col">
                    <span>{longName || balance.currency}</span>
                    <span className="text-neutral-400 text-2xs sm:text-xs">{balance.currency}</span>
                </div>
            </div>
            <div
                className={cn('flex flex-[2_1_0] relative items-center justify-center gap-2 text-right', {
                    [balanceColor]: true
                })}>
                <span className="flex-[1_1_0]">{formatQty.format(balance.netPosition)}</span>
            </div>
        </div>
    );
};
