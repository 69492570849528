import { useAppSelector } from '@/state/hooks';
import { selectAggregatedBalance } from '@/state/reducers/balanceSlice';
import { useMemo } from 'react';
import OldBalance from './OldBalance';

interface BalancesProps {}

export default function Balances(props: BalancesProps) {
    const celerBalance = useAppSelector(selectAggregatedBalance);

    const balances: any = useMemo(() => {
        return celerBalance;
    }, [celerBalance]);

    return (
        <div className="flex flex-col h-full text-gray-200">
            <div className="flex justify-between p-2 pb-0 text-sm px-3">
                <div className="text-left flex-[1_1_0]">Currency</div>
                <div className="flex flex-[2_1_0] relative justify-center gap-2">
                    <div className="text-right flex-[1_1_0]">Balance</div>
                </div>
            </div>
            <div className="w-full flex flex-col relative self-stretch grow basis-full overflow-y-auto px-3 py-1">
                {balances.map((balance, index) => (
                    <OldBalance key={index} balance={balance} isNewlyAdded={false} />
                ))}
            </div>
        </div>
    );
}
